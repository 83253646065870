"use client";
import {
  BagIcon,
  CakeCard,
  CakeCardImprint,
  Currency,
  SanityImageType,
} from "@cakemembers/components-cake";
import {
  FormState,
  Heading3,
  Heading4,
  PrimaryButton,
  SecondaryButton,
  Text,
} from "@cakemembers/components-core";
import { useFormState, useFormStatus } from "react-dom";
import { claimPassAction } from "./actions";

export function AddToWalletModalClient({
  brandSlug,
  brandName,
  cakeCardLogo,
  cakeCardBackground,
  cakeCardValue,
  remaining,
  isInWallet,
  brandTerms,
}: {
  brandSlug: string;
  brandName: string;
  cakeCardLogo?: SanityImageType;
  cakeCardBackground?: SanityImageType;
  cakeCardValue?: string;
  remaining: number;
  isInWallet: boolean;
  brandTerms: React.ReactNode;
}) {
  async function actionWrapper(
    prevState: FormState,
    formData: FormData
  ): Promise<FormState> {
    await claimPassAction(brandSlug);
    return { status: "success" };
  }
  const [state, formAction] = useFormState(actionWrapper, { status: "start" });

  if (state.status === "success") {
    return (
      <Success
        brandSlug={brandSlug}
        brandName={brandName}
        cakeCardBackground={cakeCardBackground}
        cakeCardLogo={cakeCardLogo}
        cakeCardValue={cakeCardValue}
      />
    );
  }

  if (isInWallet) {
    return (
      <div className="max-w-[320px] mt-[32px] p-6 flex flex-col text-center items-center justify-center gap-[24px]">
        <Text>{brandName} is already in your wallet.</Text>
        <SecondaryButton href={`?brand=${brandSlug}`}>Back</SecondaryButton>
      </div>
    );
  }

  if (remaining === 0) {
    return (
      <div className="max-w-[320px] mt-[32px] p-6 flex flex-col text-center items-center justify-center gap-[24px]">
        <Text>You do not have any brand slots available in your wallet.</Text>
        <SecondaryButton href={`?brand=${brandSlug}`}>Back</SecondaryButton>
      </div>
    );
  }

  return (
    <form
      action={formAction}
      className="max-w-[320px] mt-[32px] p-6 flex flex-col text-center items-center justify-center gap-[24px]"
    >
      {cakeCardValue && (
        <div className="flex flex-col ">
          <Currency amount={cakeCardValue} size="xl" />
          <Heading4 className="uppercase flex flex-row items-center">
            <BagIcon className="mr-[8px]" /> cake card value
          </Heading4>
        </div>
      )}
      <div className="w-full">
        <Heading3 className="text-secondary">Key Terms</Heading3>
        {brandTerms}
      </div>
      <Buttons brandSlug={brandSlug} />
      <Text face="sans" className="text-primary/60">
        CAKE Cards cannot be exchanged
      </Text>
    </form>
  );
}

function Buttons({ brandSlug }: { brandSlug: string }) {
  const { pending } = useFormStatus();
  return (
    <div className="flex flex-row gap-2">
      <SecondaryButton href={`?brand=${brandSlug}`} disabled={pending}>
        No, Thanks
      </SecondaryButton>
      <PrimaryButton type="submit" disabled={pending} loading={pending}>
        Yes, Please
      </PrimaryButton>
    </div>
  );
}

function Success({
  brandSlug,
  brandName,
  cakeCardValue,
  cakeCardLogo,
  cakeCardBackground,
}: {
  brandSlug: string;
  brandName: string;
  cakeCardValue?: string;
  cakeCardLogo?: SanityImageType;
  cakeCardBackground?: SanityImageType;
}) {
  return (
    <div className="max-w-[320px] p-6 flex flex-col text-center items-center justify-center gap-[24px]">
      <CakeCard
        name={brandName}
        logoImage={cakeCardLogo}
        backgroundImage={cakeCardBackground}
        value={cakeCardValue}
        imprint={<CakeCardImprint size="sm" />}
      />
      <Heading3>WOOHOO! Let's shop!</Heading3>
      <div className="flex flex-row gap-2">
        <SecondaryButton href={`/brands`} align="center">
          More Brands
        </SecondaryButton>
        <PrimaryButton href={`/home?focus=${brandSlug}`} align="center">
          Shop Now
        </PrimaryButton>
      </div>
    </div>
  );
}
