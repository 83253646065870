"use client";

import { AddIcon, PrimaryButton, Spinner } from "@cakemembers/components-core";
import { useAuth, useSignIn } from "@clerk/nextjs";
import { redirect } from "next/navigation";
import { useEffect } from "react";

export function SessionActivator({ token }: { token: string }) {
  const auth = useAuth();
  const { isLoaded, signIn, setActive } = useSignIn();
  useEffect(() => {
    if (isLoaded) {
      (async () => {
        try {
          const result = await signIn.create({
            strategy: "ticket",
            ticket: token,
          });
          if (result.createdSessionId) {
            await setActive({ session: result.createdSessionId });
          } else {
            throw new Error("Failed to create session");
          }
        } catch (err) {
          console.error("Failed to activate session", err);
        }
      })();
    }
  }, [isLoaded, signIn]);

  if (auth.isLoaded && auth.isSignedIn) {
    return (
      <PrimaryButton href={`/onboarding?step=profile`}>
        Get Started
      </PrimaryButton>
    );
  }
  return <Spinner />;
}
