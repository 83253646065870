"use client";

import { useRouter } from "next/navigation";
import { CardState } from "./types";
import { PrimaryButton, SecondaryButton } from "@cakemembers/components-core";
import { ClaimCardFormButtons } from "./ClaimCardFormButtons";
import { copy } from "@cakemembers/utils";

export function ModalButtons({
  setCardState,
  returnUrl,
  cardState,
  targetUrl,
  notInWalletBrandSlug,
  shopCode,
}: {
  setCardState: (value: CardState) => void;
  returnUrl: string;
  cardState?: CardState;
  targetUrl?: string;
  notInWalletBrandSlug?: string;
  shopCode?: string;
}) {
  const router = useRouter();

  return (
    <div className="flex gap-4 mt-auto w-full justify-center">
      {cardState === "readyToApply&Shop" && (
        <PrimaryButton
          align="center"
          className="w-36"
          href={targetUrl}
          target="_blank"
          onClick={() => router.push(returnUrl)}
        >
          Shop Now
        </PrimaryButton>
      )}

      {cardState === "readyToCopy&Shop" && (
        <PrimaryButton
          align="center"
          className="w-44"
          href={targetUrl}
          target="_blank"
          onClick={() => {
            copy(shopCode || "");
            router.push(returnUrl);
          }}
        >
          COPY CODE & SHOP
        </PrimaryButton>
      )}

      {cardState === "available" && (
        <>
          <SecondaryButton
            align="center"
            className="w-36"
            href={targetUrl}
            target="_blank"
            onClick={() => router.push(returnUrl)}
          >
            Continue
          </SecondaryButton>
          <PrimaryButton
            align="center"
            className="w-36"
            onClick={() => setCardState("flipped")}
          >
            Claim CARD
          </PrimaryButton>
        </>
      )}

      {(cardState === "redeemed" ||
        cardState === "notAvailable" ||
        cardState === "fullWallet") && (
        <PrimaryButton
          align="center"
          className="w-36"
          href={targetUrl}
          target="_blank"
          onClick={() => router.push(returnUrl)}
        >
          Shop Now
        </PrimaryButton>
      )}

      {cardState === "loading" && (
        <PrimaryButton loading disabled align="center" className="w-36">
          Loading...
        </PrimaryButton>
      )}

      {cardState === "flipped" && notInWalletBrandSlug && (
        <ClaimCardFormButtons
          notInWalletBrandSlug={notInWalletBrandSlug}
          setCardState={setCardState}
        />
      )}
    </div>
  );
}
