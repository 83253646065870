"use client";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useRef, useState } from "react";
import { StripeProvider } from "./StripeProvider";
import {
  StripeElementsOptions,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import {
  Heading4,
  Paragraph1,
  PrimaryButton,
} from "@cakemembers/components-core";
import { Address } from "@cakemembers/types";

export function CheckoutPaymentActive({
  subscriptionId,
  clientSecret,
  returnUrl,
  billingAddress,
}: {
  subscriptionId: string;
  clientSecret: string;
  returnUrl: string;
  billingAddress: Address;
}) {
  const options: StripeElementsOptions = {
    clientSecret,
  };

  return (
    <div>
      <Heading4>Payment</Heading4>
      <StripeProvider key={clientSecret} options={options}>
        <PaymentForm returnUrl={returnUrl} billingAddress={billingAddress} />
      </StripeProvider>
    </div>
  );
}

export function PaymentForm({
  returnUrl,
  billingAddress,
}: {
  returnUrl: string;
  billingAddress: Address;
}) {
  const [loading, setLoading] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [error, setError] = useState<string>();
  const stripe = useStripe();
  const elements = useElements();

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
    fields: {
      billingDetails: {
        address: "never",
      },
    },
  };

  async function handleSubmit() {
    console.log("submit payment");

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    let successURL = "";
    if (typeof window !== "undefined" && window.location.href) {
      const currentUrl = new URL(window.location.href);
      successURL = `${currentUrl.origin}${returnUrl}`;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: successURL,
        payment_method_data: {
          billing_details: {
            address: {
              line1: billingAddress.address,
              line2: billingAddress.address2 || "",
              city: billingAddress.city,
              state: billingAddress.state,
              postal_code: billingAddress.postalCode,
              country: billingAddress.country,
            },
          },
        },
      },
    });
    console.log("error", error);
    if (error) {
      setError(error.message);
    }
    setLoading(false);
  }

  function handlePaymentChange(e: StripePaymentElementChangeEvent) {
    console.log("payment change", e);
    if (e.complete !== formComplete) {
      setFormComplete(e.complete);
    }
  }

  return (
    <div className="my-3">
      <PaymentElement
        options={paymentElementOptions}
        onChange={handlePaymentChange}
      />
      <div className="my-3">
        <PrimaryButton
          onClick={handleSubmit}
          disabled={!formComplete || !stripe || !elements || loading}
          loading={loading}
        >
          Activate Membership
        </PrimaryButton>
      </div>
      {error && <Paragraph1 className="text-red-500">{error}</Paragraph1>}
    </div>
  );
}
