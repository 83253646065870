import { Heading3, Text } from "@cakemembers/components-core";
import { CardState } from "./types";

export function TextContent({
  cardState,
  cardValue,
  brandName,
}: {
  cardState: CardState;
  cardValue?: number | string;
  brandName?: string;
}) {
  switch (cardState) {
    case "available":
      return (
        <>
          <Heading3 className="text-center font-normal">
            SAVE {cardValue ? `$${cardValue} ` : ""} WITH CAKE
          </Heading3>
          <Text className="text-center font-normal">
            CAKE members receive VIP access to current collections. CLAIM CARD
            to shop this brand as a CAKE member or CONTINUE to shop the brand's
            site without a CAKE Card.
          </Text>
        </>
      );
    case "flipped":
      return (
        <>
          <Heading3 className="text-center font-normal">
            CLAIM CAKE CARD
          </Heading3>
          <Text className="text-center font-normal">
            CAKE Card will be added to your wallet and will be available for
            immediate use.
          </Text>
        </>
      );
    case "readyToApply&Shop":
      return (
        <>
          <Heading3 className="text-center font-normal">READY TO SHOP</Heading3>
          <Text className="text-center font-normal">
            SHOP NOW to begin shopping in a new tab. Your CAKE Card will be
            automatically applied during checkout.
          </Text>
        </>
      );
    case "readyToCopy&Shop":
      return (
        <>
          <Heading3 className="text-center font-normal">READY TO SHOP</Heading3>
          <Text className="text-center font-normal">
            COPY CODE & SHOP to begin shopping in a new tab. Your CAKE Card will
            be ready to apply during checkout.
          </Text>
        </>
      );
    case "redeemed":
      return (
        <>
          <Heading3 className="text-center text-secondary font-normal">
            PREVIOUSLY REDEEMED
          </Heading3>
          <Text className="text-center font-normal">
            CAKE Card was used to make a purchase. SHOP NOW to continue to the
            brand's site and shop without a CAKE Card.
          </Text>
        </>
      );
    case "notAvailable":
      return (
        <>
          <Heading3 className="text-center text-secondary font-normal">
            SOLD OUT
          </Heading3>
          <Text className="text-center font-normal">
            Sorry, those cards went fast. Follow {brandName} to find out when we
            have more offers available. SHOP NOW to continue to the brand's site
            and shop without a CAKE Card.
          </Text>
        </>
      );
    case "fullWallet":
      return (
        <>
          <Heading3 className="text-center text-secondary font-normal">
            WALLET FULL
          </Heading3>
          <Text className="text-center font-normal">
            Your wallet is currently full so you can't add this CAKE Card. SHOP
            NOW to begin shopping in a new tab without a CAKE Card.
          </Text>
        </>
      );
    case "loading":
      return (
        <>
          <Heading3 className="text-center mx-auto text-transparent font-normal w-fit bg-gray-300 rounded-md animate-pulse">
            WALLET FULL
          </Heading3>

          <Text className="text-center mx-auto text-transparent font-normal w-fit bg-gray-300 rounded-md animate-pulse">
            Lorem Ipsum Lorem Ipsum
          </Text>
          <Text className="text-center mx-auto text-transparent font-normal w-fit bg-gray-300 rounded-md animate-pulse">
            Lorem Ipsum is simply dummy text of
          </Text>
          <Text className="text-center mx-auto text-transparent font-normal w-fit bg-gray-300 rounded-md animate-pulse">
            Lorem Ipsum
          </Text>
        </>
      );

    default:
      // this'll throw an error if you dont implement all states
      const exhaustiveCheck: never = cardState;
      throw new Error(`Unhandled case: ${exhaustiveCheck}`);
  }
}
