"use client";
import {
  Checkbox,
  Heading3,
  Modal,
  PrimaryButton,
  Text,
} from "@cakemembers/components-core";
import { copy } from "@cakemembers/utils";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { updateLeaveSiteConsent } from "./actions";
import { ShopMethod } from "./ShopCopyApplyModal";

export function ShopCopyApplyComponent({
  shopApplyUrl,
  shopMethod,
  returnUrl,
  shopCakeCard,
  shopText,
  shopCode,
}: {
  shopApplyUrl: string;
  shopMethod: ShopMethod;
  returnUrl: string;
  shopCakeCard: JSX.Element;
  shopText: string;
  shopCode?: string;
}) {
  const [checked, setChecked] = useState(false);
  const router = useRouter();

  const onShopClick = () => {
    if (shopMethod === "copy") {
      copy(shopCode || "");
    }

    if (checked) {
      updateLeaveSiteConsent();
    }

    window.open(shopApplyUrl, "_blank");
    router.push(returnUrl);
  };

  const onClose = () => {
    if (checked) {
      updateLeaveSiteConsent();
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const mainElements = document.getElementsByTagName("main");
    if (mainElements && mainElements.length > 0) {
      mainElements[0].style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
      const mainElements = document.getElementsByTagName("main");
      if (mainElements && mainElements.length > 0) {
        mainElements[0].style.overflow = "auto";
      }
    };
  }, []);

  return (
    <Modal returnHref={returnUrl} onClose={onClose}>
      <div className="flex flex-col max-w-[414px] w-full items-center p-4">
        <div className="pb-4">{shopCakeCard}</div>
        <Heading3 className="text-center font-normal pb-2">
          Ready to Shop!
        </Heading3>
        <Text weight="light" className="text-center pb-4 px-6">
          {shopText}
        </Text>
        <Checkbox
          checked={checked}
          onChange={() => setChecked((prev) => !prev)}
          name="consent"
          wrapperClassName="items-center pb-6"
          label={
            <Text size="xs" face="sans" weight="regular">
              Got it, hide this in the future
            </Text>
          }
        />
        <PrimaryButton align="center" onClick={onShopClick} className="w-36">
          Shop now
        </PrimaryButton>
      </div>
    </Modal>
  );
}
