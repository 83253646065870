import { motion } from "framer-motion";
import styles from "./FlipCard.module.scss";

export function FlipCard({
  front,
  back,
  isFlipped,
}: {
  front?: JSX.Element;
  back?: JSX.Element;
  isFlipped?: boolean;
}) {
  return (
    <div className={styles.FlipCard}>
      <div className={styles["flip-card"]}>
        <motion.div
          className={styles["flip-card-inner"]}
          initial={false}
          animate={{ rotateY: isFlipped ? 180 : 360 }}
          transition={{ duration: 0.6, animationDirection: "normal" }}
        >
          <div className={styles["flip-card-front"]}>{front}</div>
          <div className={styles["flip-card-back"]}>{back}</div>
        </motion.div>
      </div>
    </div>
  );
}
