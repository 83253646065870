"use client";

import { LottieInstance } from "@cakemembers/components-cake";
import { useRouter } from "next/navigation";

export function ClientModalComponent({ returnUrl }: { returnUrl: string }) {
  const router = useRouter();

  const onAnimationEnd = () => {
    router.push(returnUrl);
  };

  return (
    <LottieInstance
      path="https://storage.googleapis.com/cake-public/brands-grid.json"
      onComplete={onAnimationEnd}
      specificHeight="h-[calc(100dvh-90px)]"
    />
  );
}
