"use client";

import {
  FormAction,
  FormState,
  GhostButton,
  Paragraph1,
  PrimaryButton,
  Spinner,
  Text,
  TextInput,
  VerifyCode,
} from "@cakemembers/components-core";
import { useUser } from "@clerk/nextjs";
import { useState } from "react";
import {
  changeEmailAddressAction,
  checkOnboardingStatusAction,
} from "./actions";
import classNames from "classnames";
import { isClerkAPIResponseError } from "@clerk/nextjs/errors";

export function VerifyEmailStep() {
  const { isSignedIn, user } = useUser();
  const [error, setError] = useState<string>();
  const [view, setView] = useState<"start" | "verify" | "change-email">(
    "start"
  );
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);

  async function sendVerification() {
    setLoading(true);
    setError(undefined);
    console.log("sending verification", {
      user,
      verification: user?.primaryEmailAddress?.verification,
    });
    if (!isSignedIn) {
      return;
    }
    const result = await user.primaryEmailAddress?.prepareVerification({
      strategy: "email_code",
    });
    console.log("sent", result);
    setLoading(false);
    setView("verify");
  }

  async function handleCodeEntered(code: string) {
    console.log("received code", code);
    setLoadingVerify(true);
    setError(undefined);
    try {
      const result = await user?.primaryEmailAddress?.attemptVerification({
        code,
      });
      await checkOnboardingStatusAction();
    } catch (err) {
      console.error(err);
      if (isClerkAPIResponseError(err)) {
        console.log("attemptVerification error", err, err.errors);
        if (err.errors[0].code === "form_code_incorrect") {
          setError("Verification code is incorrect. Please try again.");
        }
        if (err.errors[0].code === "verification_already_verified") {
          await checkOnboardingStatusAction();
        }
      } else {
        setError("Error validating code. Please try again.");
      }
    } finally {
      setLoadingVerify(false);
    }
  }

  async function changeEmailAddressActionHandler(
    prevState: FormState,
    formData: FormData
  ) {
    const result = await changeEmailAddressAction(prevState, formData);
    if (result.status === "success") {
      setView("start");
      // make sure the clerk user is up to date client-side
      await user?.reload();
    }
    return result;
  }

  if (view === "verify") {
    return (
      <div className="flex flex-col gap-4">
        <p>Check your email inbox for your 6 digit verification code.</p>
        <VerifyCode
          name={"code"}
          digits={6}
          onCodeEntered={handleCodeEntered}
        />
        <div className={classNames(!loadingVerify && "invisible")}>
          <Spinner />
        </div>

        {error && <Paragraph1 className="text-secondary">{error}</Paragraph1>}
        <GhostButton
          onClick={sendVerification}
          disabled={loading || loadingVerify}
        >
          Resend Code
        </GhostButton>
      </div>
    );
  } else if (view === "change-email") {
    return (
      <FormAction
        action={changeEmailAddressActionHandler}
        cta="Submit"
        className="w-full flex flex-col gap-4 items-center"
        secondaryButton={
          <GhostButton
            className="text-primary/60 uppercase"
            onClick={() => setView("start")}
            align="center"
          >
            Cancel
          </GhostButton>
        }
      >
        <TextInput
          disabled
          name="existingEmail"
          label="Current Email Address"
          type="email"
          value={user?.primaryEmailAddress?.emailAddress}
        />
        <TextInput name="email" label="New Email Address" type="email" />
      </FormAction>
    );
  }

  // if (user?.primaryEmailAddress?.verification.status === "verified") {
  //   return <PrimaryButton href={`/`}>Continue</PrimaryButton>
  // }

  return (
    <div className="flex flex-col gap-4 items-center">
      <Text size="lg">
        The last step in activating your account is to verify your email
        address. Upon submission, you will receive a 6 digit verification code
        in the email you provided at checkout.
      </Text>
      <Text face="sans" weight="bold" className="text-gray-500">
        {user?.primaryEmailAddress?.emailAddress}
      </Text>
      <PrimaryButton className="w-64" onClick={sendVerification} align="center">
        Send Verification Email
      </PrimaryButton>
      <GhostButton
        className="mt-6 w-64 text-primary/60 uppercase"
        onClick={() => setView("change-email")}
        align="center"
      >
        Change Email Address
      </GhostButton>
    </div>
  );
}
