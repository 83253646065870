"use client";
import {
  FormState,
  PrimaryButton,
  SecondaryButton,
  Text,
} from "@cakemembers/components-core";
import { useEffect } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { claimPassAction } from "./actions";
import { CardState } from "./types";

export function ClaimCardFormButtons({
  notInWalletBrandSlug,
  setCardState,
}: {
  notInWalletBrandSlug: string;
  setCardState: (value: CardState) => void;
}) {
  const [state, formAction] = useFormState(actionWrapper, {
    status: "start",
  });

  useEffect(() => {
    if (state.status === "success") {
      setCardState("loading");
    }
  }, [state]);

  async function actionWrapper(
    prevState: FormState,
    formData: FormData
  ): Promise<FormState> {
    const isClaimed = await claimPassAction(notInWalletBrandSlug);
    if (!isClaimed) return { status: "error", message: "Claim Card Error" };

    return { status: "success" };
  }
  return (
    <form action={formAction}>
      {state.status === "error" && (
        <Text size="sm" className="text-secondary text-center">
          {state.message}
        </Text>
      )}
      <Buttons onDecline={() => setCardState("available")} />
    </form>
  );
}

function Buttons({ onDecline }: { onDecline: () => void }) {
  const { pending } = useFormStatus();
  return (
    <div className="flex gap-4 w-full justify-center">
      <SecondaryButton
        align="center"
        className="w-36"
        onClick={onDecline}
        disabled={pending}
      >
        No, Thanks
      </SecondaryButton>
      <PrimaryButton
        align="center"
        className="w-36"
        type="submit"
        disabled={pending}
        loading={pending}
      >
        Yes, Please
      </PrimaryButton>
    </div>
  );
}
