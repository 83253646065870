"use client";

import { FormState, PrimaryButton } from "@cakemembers/components-core"
import { useEffect, useState } from "react"
import { useFormStatus } from "react-dom"

export function SubmitAddress({
  formState,
  cta,
  formValid,
  secondaryButton,
  disabled,
}: {
  formState: FormState;
  cta: React.ReactNode;
  formValid: boolean;
  secondaryButton?: React.ReactNode;
  disabled?: boolean;
}) {
  const [isSuccess, setIsSuccess] = useState(false);
  const status = useFormStatus();

  useEffect(() => {
    if (formState.status === "success" && !isSuccess) {
      setIsSuccess(true);
    }
  }, [formState]);

  return (
    <PrimaryButton
      disabled={disabled}
      align="center"
      className="w-40 mx-auto"
      loading={status.pending}
      type={!isSuccess ? "submit" : "button"}
    >
      <span>Continue</span>
    </PrimaryButton>
  );
}
