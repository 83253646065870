"use client";
import {
  FormAction,
  FormState,
  Heading4,
  Spinner,
  TextInput,
} from "@cakemembers/components-core";
import { useLoadScript } from "@react-google-maps/api";
import { Suspense, useEffect, useRef, useState } from "react";
import { Section } from "./Section";
import { SubmitAddress } from "./SubmitAddress";
import { Libraries } from "@react-google-maps/api";
import { CartCookie } from "@cakemembers/types";

declare global {
  interface Window {
    gm_authFailure?: () => void;
  }
}

interface AddressAutoSuggestProps {
  cart: CartCookie;
  submitAction(prevState: FormState, formData: FormData): Promise<FormState>;
}

interface InputState {
  address: string;
  address2: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
  latitude: number | null;
  longitude: number | null;
}

const libraries: Libraries = ["places"];

export function AddressAutoSuggest({
  cart,
  submitAction,
}: AddressAutoSuggestProps) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
    libraries,
  });

  const [input, setInput] = useState<InputState>({
    address: cart.address?.address || "",
    address2: cart.address?.address2 || "",
    city: cart.address?.city || "",
    country: "US",
    postalCode: cart.address?.postalCode || "",
    state: cart.address?.state || "",
    latitude: null,
    longitude: null,
  });
  const inputAddressRef = useRef<HTMLInputElement | null>(null);

  const handlePlaceChanged = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    if (!isLoaded) return;
    const place = autocomplete.getPlace();

    if (!place || !place.geometry) {
      setInput({
        address: "",
        address2: "",
        city: "",
        country: "US",
        postalCode: "",
        state: "",
        latitude: null,
        longitude: null,
      });
      return;
    }
    formData(place);
  };

  const formData = (data: google.maps.places.PlaceResult) => {
    const addressComponents = data?.address_components || [];

    const componentMap: { [key: string]: string } = {
      subPremise: "",
      premise: "",
      street_number: "",
      route: "",
      country: "",
      postal_code: "",
      locality: "",
      administrative_area_level_1: "",
    };

    for (const component of addressComponents) {
      const componentType = component.types[0];
      if (Object.prototype.hasOwnProperty.call(componentMap, componentType)) {
        componentMap[componentType] = component.long_name;
      }
    }

    const formattedAddress =
      `${componentMap.subPremise} ${componentMap.premise} ${componentMap.street_number} ${componentMap.route}`.trim();

    setInput((values) => ({
      ...values,
      address: formattedAddress,
      country: componentMap.country,
      postalCode: componentMap.postal_code,
      city: componentMap.locality,
      state: componentMap.administrative_area_level_1,
    }));
  };

  useEffect(() => {
    if (!isLoaded || loadError || !inputAddressRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry"],
    };

    const autocomplete = new google.maps.places.Autocomplete(
      inputAddressRef.current,
      options
    );

    const autocompleteLstnr = autocomplete.addListener("place_changed", () =>
      handlePlaceChanged(autocomplete)
    );

    return () => {
      google.maps.event.removeListener(autocompleteLstnr);
    };
  }, [loadError, isLoaded]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    // In case of Google Places Api error (wrong key/blocked referrer)
    window.gm_authFailure = () => {
      const inputElement = inputAddressRef.current;

      if (inputElement) {
        inputElement.disabled = false;
        inputElement.placeholder = "Address";
        inputElement.style.backgroundImage = "";
        inputElement.focus();
      }
    };
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Section>
        <FormAction
          key="checkout-address"
          action={submitAction}
          submitButton={SubmitAddress}
          cta={"Continue"}
          className="flex flex-col gap-3"
          clearOnSuccess={false}
        >
          <Heading4>Billing Address</Heading4>
          <TextInput
            ref={inputAddressRef}
            name="address"
            label="Address"
            placeholder="Address"
            required
            value={input.address}
            onChange={(e) => setInput({ ...input, address: e.target.value })}
          />
          <TextInput
            name="address2"
            label="Address 2"
            placeholder="Address 2"
            value={input.address2}
            onChange={(e) => setInput({ ...input, address2: e.target.value })}
          />
          <TextInput
            name="city"
            label="City"
            placeholder="City"
            required
            value={input.city}
            onChange={(e) => setInput({ ...input, city: e.target.value })}
          />
          <TextInput
            name="state"
            label="State"
            placeholder="State"
            required
            value={input.state}
            onChange={(e) => setInput({ ...input, state: e.target.value })}
          />
          <TextInput
            name="postalCode"
            label="Postal Code"
            placeholder="Postal Code"
            required
            value={input.postalCode}
            onChange={(e) => setInput({ ...input, postalCode: e.target.value })}
          />
          <input type="hidden" name="country" value="US" />
          <TextInput
            name="countryCodeDisplay"
            label="Country"
            placeholder="Country"
            value="US"
            disabled
            required
            helperText="Currently we are only serving the USA."
          />
        </FormAction>
      </Section>
    </Suspense>
  );
}
