"use client";

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

export function SwiperList({ children }: { children: React.ReactNode }) {
  const mappedChildren = React.Children.map(children, (child) => (
    <SwiperSlide>{child}</SwiperSlide>
  ));
  return (
    <Swiper
      spaceBetween={20}
      breakpoints={{
        320: {
          slidesPerView: 2.2,
        },
        768: {
          slidesPerView: 2.2,
        },
        850: {
          slidesPerView: 3.2,
        },
      }}
    >
      {mappedChildren}
    </Swiper>
  );
}
