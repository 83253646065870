"use client";

// This button is not used anywhere, but it's here in case it's needed
import { copy } from "@cakemembers/utils";
import { PrimaryButton } from "@cakemembers/components-core";
import { useToast } from "@cakemembers/components-motion";
import { useState } from "react";

export function CopyAndShopButton({
  website,
  code,
}: {
  website: string;
  code: string;
}) {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  function handleClick() {
    copy(code);
    addToast("Copied!");
    window.open(website, "_blank");

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  return (
    <PrimaryButton
      className="w-full"
      align="center"
      onClick={handleClick}
      loading={loading}
    >
      Copy Code & Shop
    </PrimaryButton>
  );
}
