"use client";

import { Modal } from "@cakemembers/components-core";
import { MotionDiv } from "@cakemembers/components-motion";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FlipCard } from "./FlipCard";
import { ModalButtons } from "./ModalButtons";
import { TextContent } from "./TextContent";
import { CardState } from "./types";

export function ProcessShopLinkComponent({
  targetUrl,
  returnUrl,
  cardState,
  shopCakeCard,
  backSideCakeCard,
  cardValue,
  shopCode,
  notInWalletBrandSlug,
  brandTerms,
  brandName,
}: {
  targetUrl?: string;
  returnUrl?: string;
  cardState: CardState;
  shopCakeCard?: JSX.Element;
  backSideCakeCard?: JSX.Element;
  cardValue?: number | string;
  shopCode?: string;
  notInWalletBrandSlug?: string;
  brandTerms?: JSX.Element;
  brandName?: string;
}) {
  const [cardLocalState, setLocalCardState] = useState<CardState>(cardState);

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, []);

  useEffect(() => {
    if (cardState) {
      setLocalCardState(cardState);
    }
  }, [cardState]);

  const motionProps = {
    initial: isInitialRender ? {} : { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <Modal returnHref={returnUrl || "/home"}>
      <div className="flex flex-col max-w-[414px] min-h-[460px] sm:min-h-[480px] h-full gap-4 w-full items-center p-4">
        <FlipCard
          front={
            <div
              className={classNames(
                (cardLocalState === "notAvailable" ||
                  cardLocalState === "redeemed") &&
                  "grayscale"
              )}
            >
              {shopCakeCard}
            </div>
          }
          back={
            <div className="relative darkSection text-neutral-content h-full">
              <div className="absolute">{backSideCakeCard}</div>
              <div className="relative h-full">{brandTerms}</div>
            </div>
          }
          isFlipped={cardLocalState === "flipped"}
        />
        <MotionDiv
          key={cardLocalState}
          {...motionProps}
          className="flex flex-col gap-2"
        >
          <TextContent
            cardState={cardLocalState}
            cardValue={cardValue}
            brandName={brandName}
          />
        </MotionDiv>
        <ModalButtons
          cardState={cardLocalState}
          setCardState={setLocalCardState}
          returnUrl={returnUrl || "/home"}
          notInWalletBrandSlug={notInWalletBrandSlug}
          targetUrl={targetUrl}
          shopCode={shopCode}
        />
      </div>
    </Modal>
  );
}
